import { NGXLoggerServerService } from 'ngx-logger';
import { Injectable, NgZone } from '@angular/core';
import { HttpBackend, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';

@Injectable()
export class CustomLoggerServerService extends NGXLoggerServerService {

  constructor(private xsrfTokenExtractor: HttpXsrfTokenExtractor, httpBackend: HttpBackend, ngZone: NgZone) {
    super(httpBackend, ngZone)
  }

  protected override alterHttpRequest(httpRequest: HttpRequest<any>): HttpRequest<any> {
    const csrfToken: string = this.xsrfTokenExtractor.getToken();
    const csrfHeaderName: string = 'X-XSRF-TOKEN'
    if (csrfToken && !httpRequest.headers.has(csrfHeaderName)) {
      return httpRequest.clone({ headers: httpRequest.headers.set(csrfHeaderName, csrfToken) });
    }
  }
}
