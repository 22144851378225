import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ConfigurationService } from './configuration/configuration.service';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { GlobalErrorHandler } from './error-handler/error-handler';
import { RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './error-pages/page-not-found.component';
import { RegistrationComponent } from './registration/registration.component';
import { LoginFailedComponent } from './error-pages/login-failed.component';
import { NGXLogger } from 'ngx-logger';
import { LoggerMonitor } from './monitor/logger-monitor';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { UpdateDialogComponent } from './update/update-dialog.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { MatSelectModule } from '@angular/material/select';

export function initializeLoggerMonitor(logger: NGXLogger) {
  return () => {
    logger.registerMonitor(new LoggerMonitor());
    logger.debug('Logger Monitor erfolgreich registriert');
  };
}

export function initializeConfiguration(
  appConfigService: ConfigurationService
) {
  return () => {
    // Make sure to return a promise!
    return appConfigService.loadAppConfig();
  };
}

@NgModule({
  declarations: [
    RegistrationComponent,
    ErrorDialogComponent,
    InfoDialogComponent,
    PageNotFoundComponent,
    LoginFailedComponent,
    UpdateDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSelectModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [NGXLogger],
      useFactory: initializeLoggerMonitor,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [ConfigurationService],
      useFactory: initializeConfiguration,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
  ],
})
export class CoreModule {}
