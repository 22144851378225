import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { NGXLogger } from 'ngx-logger';

@Injectable({ providedIn: 'root' })
export class LogUpdateService {
  constructor(updates: SwUpdate, logger: NGXLogger) {
    updates.versionUpdates.subscribe(event => {
      switch (event.type) {
        case 'VERSION_DETECTED':
          logger.debug(`Downloading new app version: ${event.version.hash}`);
          break
        case 'VERSION_READY':
          logger.debug(`Current app version is: ${event.currentVersion.hash}`);
          logger.debug(`Available app version is: ${event.latestVersion.hash}`);
          break
        case 'VERSION_INSTALLATION_FAILED':
          logger.debug(`Failed to install app version '${event.version.hash}' Reason: ${event.error}`);
          break
      }
    });
  }
}
