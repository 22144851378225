<div class="app-content">
  <div class="app-container">
    <div class="app-title-image"></div>
    <h1 class="app-headline">Uups! 404</h1>
    <div class="app-centered">
      Leider konnten wir die Seite, nach der Sie suchen, nicht finden.
    </div>
    <div class="app-form app-centered">
      <a mat-flat-button color="accent" routerLink="/">
        Zur Startseite
      </a>
    </div>
  </div>
</div>
