<div class="app-content">
  <div class="app-container">
    <div class="app-title-image"></div>
    <h1 class="app-headline">Anmeldung fehlgeschlagen!</h1>
    <div class="app-centered">
      Wir konnten Sie leider am System aufgrund eines technischen Problems nicht
      anmelden.<br />Bitte versuchen Sie es nach einiger Zeit erneut.
    </div>
    <div class="app-form app-centered">
      <a mat-flat-button color="accent" routerLink="/">
        Erneut anmelden
      </a>
    </div>
  </div>
</div>
