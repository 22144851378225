import { Injectable } from '@angular/core';
import { InMemoryDbService } from 'angular-in-memory-web-api';

@Injectable({
  providedIn: 'root',
})
export class AppInMemoryDataService implements InMemoryDbService {
  constructor() {}

  createDb() {
    const configuration = {
      timezone: 'Europe/Berlin',
      locale: 'de-de',
      logLevel: 0,
    };

    return {
      configuration,
      getUserInfo: {
        emailAddress: 'test@test.com',
        products: ['ladeloesung'],
        address: {
          city: 'München',
          country: 'DE',
          houseNr: '12',
          street: 'XY-Str.',
          zipCode: '81234',
          addressSupplement: '',
        },
        line: '',
        mobile: '',
        profile: {
          anrede: 'Herr',
          familyName: 'Mustermann',
          givenName: 'Max',
        },
        paymentDetails: {
          type: 'MasterCard',
          lastDigits: '123',
        },
        odoo: {
          customerNumber: 'C123',
          companyName: '',
          taxId: '',
        },
      },
    };
  }
}
