import { INGXLoggerMonitor } from 'ngx-logger/lib/monitor/ilogger-monitor';
import { INGXLoggerConfig, INGXLoggerMetadata } from 'ngx-logger';

declare function logMessage(message: string): any;

export class LoggerMonitor implements INGXLoggerMonitor {
  levels = ['TRACE', 'DEBUG', 'INFO', 'LOG', 'WARN', 'ERROR', 'FATAL'];

  onLog(log: INGXLoggerMetadata, config: INGXLoggerConfig): void {
    let message = log.timestamp;
    message += ' [' + this.levels[log.level.valueOf()] + ']';
    message += ' ' + log.message;

    log.additional.forEach(elem => {
      let str: string;

      if (!(elem instanceof Object)) {
        str = elem;
      } else if (elem.length) {
        str = elem;
      } else {
        str = JSON.stringify(elem);
      }
      message += '\n    ' + str;
    });
    logMessage(message);
  }
}
