<h2 mat-dialog-title>
  <div class="app-update-icon"></div>
  Eine neue Version der App ist verfügbar
</h2>
<div mat-dialog-content>
  <p>Nach der Aktualisierung wird die App automatisch neu gestartet.</p>
</div>
<div mat-dialog-actions>
  <button
    mat-flat-button
    color="accent"
    [mat-dialog-close]="true"
    cdkFocusInitial
  >
    Aktualisieren
  </button>
  <button mat-stroked-button color="primary" [mat-dialog-close]="false">
    Jetzt nicht
  </button>
</div>
