import { ApplicationRef, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { UpdateDialogComponent } from './update-dialog.component';

@Injectable({ providedIn: 'root' })
export class UpdateService {
  constructor(
    private appRef: ApplicationRef,
    private updates: SwUpdate,
    private dialog: MatDialog
  ) {
    // Activate PWA-funcionality only if a service worker is available
    // (the service worker is only available in prod environment)
    if (this.updates.isEnabled) {
      this.promptForUpdate();
      this.checkForUpdate();
    }
  }

  private promptForUpdate() {
    this.updates.versionUpdates.pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY')).subscribe(event => {
      // Update was recognized

      // Inform user about the update and ask to reload page (means install the update)

      this.dialog
        .open(UpdateDialogComponent, { maxWidth: '480px' })
        .afterClosed()
        .subscribe(result => {
          if (result) {
            this.updates
              .activateUpdate()
              .then(() => document.location.reload());
          }
        });
    });
  }

  private checkForUpdate() {
    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(
      first(isStable => isStable === true)
    );
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() =>
      this.updates.checkForUpdate()
    );
  }
}
