import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare function logMessage(message: string);

logMessage('Bootstrapping der App wird gestartet');

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(logMessage('Bootstrapping erfolgreich!'))
  .catch(err => console.error(err));
