import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from '../user/user.service';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
})
export class RegistrationComponent implements OnInit {
  form = new UntypedFormGroup({
    product: new UntypedFormControl({ value: '' }, Validators.required),
    pin: new UntypedFormControl(
      { value: undefined, disabled: false },
      Validators.required
    ),
  });

  constructor(private router: Router, private user: UserService) {}

  ngOnInit(): void {
    const product = this.form.get('product');
    const pin = this.form.get('pin');

    product.valueChanges.subscribe((val) => {
      if (product.valid) {
        pin.enable();
      }
    });
  }

  onSubmit() {
    let product = this.form.get('product').value;
    let pin = this.form.get('pin').value;
    if (product === 'ladeloesung') {
      pin = null;
    }
    this.user.registerProduct(product, pin).subscribe(() => {
      this.router.navigateByUrl(
        '/' + this.form.get('product').value + '/welcome'
      );
    });
  }

  isButtonDisabled(): boolean {
    return (
      this.form.controls.product.value !== 'ladeloesung' &&
      this.form.controls.pin.invalid
    );
  }
}
