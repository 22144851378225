import { BrowserModule, Title } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { environment } from '../environments/environment';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { AppInMemoryDataService } from './app-in-memory-data.service';
import { CoreModule } from './core/core.module';
import { HTTP_INTERCEPTOR_PROVIDERS } from './core/interceptors/interceptors';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoggerModule, TOKEN_LOGGER_SERVER_SERVICE } from 'ngx-logger';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ConfigurationService } from './core/configuration/configuration.service';
import { CustomLoggerServerService } from './core/monitor/custom-logger-server-service';

export function localeIdFactory() {
  return (configuration: ConfigurationService): string => {
    return configuration.locale;
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    environment.production
      ? []
      : HttpClientInMemoryWebApiModule.forRoot(AppInMemoryDataService, {
        passThruUnknownUrl: true,
        apiBase: environment.apiUrl,
      }),

    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.serviceWorkerEnabled
    }),
    LoggerModule.forRoot(
      { level: environment.logLevel }, {
        serverProvider: {
          provide: TOKEN_LOGGER_SERVER_SERVICE, useClass: CustomLoggerServerService,
        },
      }),
    AppRoutingModule,
    CoreModule,
  ],
  providers: [
    Title,
    HTTP_INTERCEPTOR_PROVIDERS,
    {
      provide: LOCALE_ID,
      useFactory: localeIdFactory(),
      deps: [ConfigurationService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
