import { inject, NgModule } from '@angular/core';

import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { PageNotFoundComponent } from './core/error-pages/page-not-found.component';
import { AuthenticationGuard } from './core/user/authentication.guard';
import { RegistrationComponent } from './core/registration/registration.component';
import { LoginFailedComponent } from './core/error-pages/login-failed.component';

const routes: Routes = [
  {
    path: '',
    runGuardsAndResolvers: 'always',
    canActivate: [(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => inject(AuthenticationGuard).canActivate(next, state)],
    children: [
      {
        path: 'solar',
        loadChildren: () =>
          import('./solar/solar.module').then((mod) => mod.SolarModule),
      },
      {
        path: 'ladeloesung',
        loadChildren: () =>
          import('./ladeloesung/ladeloesung.module').then(
            (mod) => mod.LadeloesungModule
          ),
      },
      {
        path: 'partnerkraft',
        loadChildren: () =>
          import('./partnerkraft/partnerkraft.module').then(
            (mod) => mod.PartnerkraftModule
          ),
      },
      {
        path: 'sonnenbausteine',
        loadChildren: () =>
          import('./sonnenbausteine/sonnenbausteine.module').then(
            (mod) => mod.SonnenbausteineModule
          ),
      },
      {
        path: 'card-ordering',
        loadChildren: () =>
          import('./card-ordering/card-ordering.module').then(
            (mod) => mod.CardOrderingModule
          ),
      },
    ],
  },
  {
    path: 'registration',
    component: RegistrationComponent,
    data: {
      pageTitle: 'Registrierung',
    },
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent,
    data: {
      pageTitle: 'Anmeldung fehlgeschlagen',
    },
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    data: {
      pageTitle: 'Fehler 404: Seite nicht gefunden',
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
