<app-navigation>
  <h1 class="app-headline">Willkommen bei SWM more!</h1>
  <div class="app-centered">
    Bitte wählen Sie Ihr Produkt aus, um loszulegen.
  </div>
  <form
    class="app-form app-centered"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
  >
    <mat-form-field>
      <mat-label>Produkt auswählen</mat-label>
      <mat-select
        formControlName="product"
        required
      >
        <mat-option value="ladeloesung">M/Ladelösung</mat-option>
        <mat-option value="partnerkraft">M/Partnerkraft</mat-option>
        <mat-option value="solar">M/Solar</mat-option>
        <mat-option value="sonnenbausteine">M/Solar Sonnenbausteine</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field id="pin" *ngIf="form.controls.product.value !== 'ladeloesung'">
      <mat-hint>Bei einer PIN-Eingabe haben Sie Ihre PIN in einem separaten Brief per Post erhalten.</mat-hint>
      <mat-label>PIN</mat-label>
      <input matInput
             matNativeControl
             required
             formControlName="pin"
      />
    </mat-form-field>
    <div class="app-form-footer">
      <button
        id="button"
        type="submit"
        mat-flat-button
        color="primary"
        [disabled]="isButtonDisabled()"
      >
        Registrierung abschließen
      </button>
    </div>
  </form>
</app-navigation>
