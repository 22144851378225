import {
  Component,
  ErrorHandler,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { ConfigurationService } from './core/configuration/configuration.service';
import * as moment from 'moment-timezone';
import { GlobalErrorHandler } from './core/error-handler/error-handler';
import { MatDialog } from '@angular/material/dialog';
import { ErrorDialogComponent } from './core/error-dialog/error-dialog.component';
import { DOCUMENT, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { UpdateService } from './core/update/update.service';
import { LogUpdateService } from './core/update/log-update.service';
import { TitleService } from './core/title/title.service';
import { NGXLogger } from 'ngx-logger';
import { ScreenService } from './core/screen/screen.service';
import { Platform } from '@angular/cdk/platform';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import {
  difference,
  filter as filterArray,
  isEmpty,
  split,
  union,
  uniq,
} from 'lodash';
import {
  getAppPageConfiguration,
  PageConfiguration,
} from './shared/component-base/app-page.component';

@Component({
  selector: 'app-root',
  template: `
    <div
      [ngClass]="{
        'app-platform-ios': platform.IOS,
        'app-mobile': screen.device === 'mobile',
        'app-desktop': screen.device === 'desktop'
      }"
    >
      <router-outlet></router-outlet>
    </div>
  `,
})
export class AppComponent implements OnInit {
  constructor(
    public platform: Platform,
    public screen: ScreenService,
    @Inject(DOCUMENT) private document,
    private renderer: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    configurationService: ConfigurationService,
    title: TitleService,
    logger: NGXLogger,
    updates: UpdateService,
    logUpdates: LogUpdateService,
    @Inject(ErrorHandler) errorHandler: GlobalErrorHandler,
    dialog: MatDialog
  ) {
    logger.updateConfig({
      level: configurationService.logLevel,
      serverLogLevel: configurationService.serverLogLevel,
      serverLoggingUrl: configurationService.serverLoggingUrl,
    });
    logger.debug('App Komponenente wird initialisert');
    // Set timezone and locale to fixed value for whole app
    moment.tz.setDefault(configurationService.timezone);
    moment.locale(configurationService.locale);

    registerLocaleData(localeDe);

    errorHandler.getErrors().subscribe((error) => {
      dialog.open(ErrorDialogComponent, {
        data: { error },
        width: '328px',
      });
    });
  }

  ngOnInit() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route) => {
        if (route && route.component) {
          let pageConfig = getAppPageConfiguration(route.component) || {};
          this.updateBodyClasses(pageConfig);
        }
      });
  }

  private updateBodyClasses(pageConfiguration?: PageConfiguration) {
    let body = this.document?.body;
    if (!body) {
      return;
    }

    try {
      let actualBodyClasses = AppComponent.getClasses(body);
      let additionalClasses = filterArray(
        split(pageConfiguration?.bodyClasses),
        (cls) => !isEmpty(cls)
      );
      let expectedClasses = union(this.DEFAULT_BODY_CLASSES, additionalClasses);
      let classesToAdd = difference(expectedClasses, actualBodyClasses);
      let classesToRemove = difference(actualBodyClasses, expectedClasses);
      classesToRemove.forEach((cls) => {
        this.renderer.removeClass(body, cls);
      });
      classesToAdd.forEach((cls) => {
        this.renderer.addClass(body, cls);
      });
    } catch (e) {
      console.error('Unable to update body classes', e);
    }
  }

  private static getClasses(element: Element): Array<string> {
    let result = [];
    element.classList.forEach((cls) => result.push(cls));
    return uniq(result);
  }

  private readonly DEFAULT_BODY_CLASSES = [
    'mat-app-background',
    'mat-typography',
  ];
}
