import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { UserService } from './user.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuard {
  constructor(
    private user: UserService,
    private router: Router,
    private logger: NGXLogger
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.logger.trace('AuthenticationGuard: Navigation beginnt', state.url);
    if (this.user.isAuthenticated()) {
      const targetApp = this.user.getTargetApp();
      const tree = this.router.parseUrl(state.url);
      this.logger.trace(
        'AuthenticationGuard: Benutzer ist authentifiziert, App=' + targetApp
      );
      if (state.url.startsWith('/card-ordering')) {
        this.logger.trace(
          `AuthenticationGuard: Navigation zu ${state.url} erfolgreich`
        );
        return true;
      } else if (!targetApp) {
        this.logger.info('AuthenticationGuard: Benutzer ist nicht registriert');
        this.router.navigateByUrl('/registration', { replaceUrl: true });
        return false;
      } else if (!tree.root.hasChildren()) {
        this.logger.trace(
          'AuthenticationGuard: Weiterleitung zur App ' + targetApp
        );
        this.router.navigateByUrl('/' + targetApp, { replaceUrl: true });
        return false;
      } else {
        this.logger.trace('AuthenticationGuard: Navigation erfolgreich');
        return true;
      }
    }
    this.logger.trace('AuthenticationGuard: Benutzer nicht authentifiziert');
    return this.user.login();
  }
}
